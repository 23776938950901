.footer
{
    /* position: absolute; */
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: auto; */
    background-color: var(--darkgrey);
    padding-left: 2%;
    padding-right: 2%;
    /* bottom: 0%; */
}

.footer .left-align
{
    /* float: left; */
}

.footer .right-align
{
    /* float: right; */
}

.footer p
{
    color: var(--white);
}

.footer a
{
    color: var(--white);
    text-decoration: none;
}

.footer a:hover
{
    color: var(--grey);
}