.services-container
{
    aspect-ratio: 6000 / 3376;
    width: 100vw;
    background-image: url("../assets/backgrounds/Beesting_Web_Projects.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
}


.services-container-mobile
{
    background-image: url("../assets/backgrounds/Beesting_Web_Projects.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding: 10%;
}

.title
{
    height: 70vh;
}

.title-row
{
    display: flex;
    width: 100vw;
}

.title-object
{
    width: 50%;
    padding-left: 3%;
}

.services-container .flex-box
{
    display: flex;
    align-items: center;
    width: 100vw;
}

img
{
    width: 100%;
}

.service-row
{
    display: flex;
}

.featureProject
{
    padding: 2%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.featureProject img
{
    width: 60%;
}

.featureProjectText
{
    padding: 5%;
}

.serviceBox
{
    padding: 2%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.serviceBox img
{
    border-radius: 25px;
    width: 100%;
}

.serviceBox p
{
    height: 70%;
}


.serviceBox-mobile
{
    padding-bottom: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.serviceBox-mobile img
{
    border-radius: 25px;
}

.services-container .left-text
{
    width: 50%;
    padding-left: 5%;
    margin: auto;
    text-align: center;
}

.services-container .centre-parting
{
    width: 15%;
}

.services-container .right-text
{
    width: 50%;
    padding-right: 5%;
    margin-bottom: 3.5%;
    /* text-align: left; */
}

a
{
    color: var(--black);
}