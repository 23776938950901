.contact-container
{
    /* width: 100vw; */
    /* height: 100vh; */
    aspect-ratio: 11850 / 6667;
    width: 100vw;
    background-image: url("../assets/backgrounds/Beesting_Web_Contact.jpg");
    background-size: 100%;
    height: 98vh;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}


.contact-container-mobile
{
    /* width: 100vw; */
    /* height: 100vh; */
    /* aspect-ratio: 11850 / 6667; */
    /* width: 100vw; */
    background-image: url("../assets/backgrounds/Beesting_Web_Contact.jpg");
    background-size: 100%;
    height: 94vh;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}


.contact-form-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

