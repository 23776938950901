body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root
{
  --black: #000000;
  --darkgrey: #4A4A4A;
  --grey: #808080;
  --white: #FFFFFF;
}

h1
{
  color: var(--black);
  font-family: 'Open Sans', sans-serif;
}

.logo
{
  float: left;
  width: 40px;
  margin: 0px;
}