
form
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    outline: none;
    font-family: sans-serif;
}

.first-name
{
    background-color: var(--darkgrey);
    width: 50vw;
    height: 20px;
    border-radius: 50px;
    border-color: var(--white);
    border-style: none;
    padding: 20px;
    margin: 10px;
    box-shadow: inset 0 0 10px 1px white;
}

.textarea
{
    font-family: sans-serif;
    color: var(--white);
    background-color: var(--darkgrey);
    width: 50vw;
    height: 200px;
    border-radius: 50px;
    border-color: var(--white);
    padding: 20px;
    margin: 10px;
    box-shadow: inset 0 0 10px 1px white;
}

input
{
    color: var(--white);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--white);
    opacity: 1; /* Firefox */
  }
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--white);
}
  
::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--white);
}

.send-button
{
    background-color: var(--darkgrey);
    width: 70px;
    font-size: 20px;
    border-radius: 50px;
    border-color: var(--white);
    border-style: none;
    margin: 10px;
    padding: 10px;
    color: var(--white);
    box-shadow: inset 0 0 10px 1px white;
}

.send-button:hover
{
    background-color: var(--grey);
}

.send-button:active
{
    background-color: var(--black);
}