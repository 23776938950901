.navibar
{
    /* position: fixed; */
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding-left: 2%;
    padding-right: 2%;
    overflow: hidden;
    width: 96%;
    display: flex;
    flex-direction: column;
}

.navibar-mobile
{

    list-style-type: none;
    margin: 0;
    padding-left: 2%;
    padding-right: 2%;
    overflow: hidden;
    width: 96%;
    display: flex;
    justify-content: space-between;
    /* border-style: solid; */
}

.bar
{
    display: flex;
    justify-content: space-between;
    height: 50px;
}

.navibar .left-navibar
{
    float: left;
}

.navibar .right-navibar
{
    float: right;
}

.navibar h3
{
    
}

li
{
    float: left;
    margin-top: 5px;
    padding: 10px;
}

li a
{
    color: var(--grey);
    text-decoration: none;
    font-size: 10pt;
}

li a:hover
{
    color: var(--darkgrey);
}

button
{
    border-style: none;
    background-color: var(--white);
}

.dropdown {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 2px;
    margin-top: 5%;
    /* padding-right: 5%; */
}

.menu-dot
{
    height: 25px;
    width: 25px;
    background-color: var(--darkgrey);
    border-radius: 50%;
    display: inline-block;
}

.menu {
    position: absolute;
display: flex;
flex-direction: column;
align-items: flex-start;
background-color: var(--white);
box-shadow: -4px 4px 4px var(--grey);
list-style-type: none;
padding: 0;
width: 100px;
}
  
.menu > li {
    margin: 0;
}
  
.menu > li > button {
    /* width: 100%;
    height: 100%;
    text-align: left;
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer; */
  }

  .beesting-text
  {
    /* padding-left: 5%; */
    /* position: absolute; */
  }